import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchasingInvoiceReportService {    
    readPurchasingInvoiceReport(){
        var query = `
            query ($startDate:String!, $endDate:String!, $statusPayment:String, $status:String) {
                GetPurchasingInvoiceReport (start_date:$startDate, end_date:$endDate, statusPayment:$statusPayment, status:$status) {
                    summary {
                        quantity_per_status {
                            new
                            posting
                            payment
                            finish
                            delete
                        }
                        total_vat_per_status {
                            new
                            posting
                            payment
                            finish
                            delete
                        }
                        total_payment
                    }
                    detail {
                        created_at
                        last_update
                        invoice_id
                        status
                        purchase_order_id
                        invoice_date
                        invoice_received_date
                        supplier_name
                        ppn_tax_percentage
                        currency_id
                        currency_name
                        exchange_rate
                        notes
                        tax_invoice
                        import_tax
                        custom_clearance
                        additional_tax
                        supplier_invoice_number
                        invoice_due_date
                        created_by
                        item_id
                        item_name
                        product_code
                        uom
                        discount
                        quantity
                        price
                        price_per_unit
                        vat_per_unit
                        vat
                        sum_total_payment
                        invoice_number
                        purchase_order_number
                        ppn_out
                        sum_total_price
                        sum_total_ppn_out
                        sum_total_vat
                        status_payment
                        delivery_order_number
                        outstanding_amount
                        total_conv_amount
                        conv_amount
                    }
                }
            }
        `;
        return query;
    }

    async getPurchasingInvoiceReport(variables) {
        var query = gql`
            query ($startDate:String!, $endDate:String!, $statusPayment:String, $status:String) {
                GetPurchasingInvoiceReport (start_date:$startDate, end_date:$endDate, statusPayment:$statusPayment, status:$status) {
                    summary {
                        quantity_per_status {
                            new
                            posting
                            payment
                            finish
                            delete
                        }
                        total_vat_per_status {
                            new
                            posting
                            payment
                            finish
                            delete
                        }
                        total_payment
                    }
                    detail {
                        created_at
                        last_update
                        invoice_id
                        status
                        purchase_order_id
                        invoice_date
                        invoice_received_date
                        supplier_name
                        ppn_tax_percentage
                        currency_id
                        currency_name
                        exchange_rate
                        notes
                        tax_invoice
                        import_tax
                        custom_clearance
                        additional_tax
                        supplier_invoice_number
                        invoice_due_date
                        created_by
                        item_id
                        item_name
                        product_code
                        uom
                        discount
                        quantity
                        price
                        price_per_unit
                        vat_per_unit
                        vat
                        sum_total_payment
                        invoice_number
                        purchase_order_number
                        ppn_out
                        sum_total_price
                        sum_total_ppn_out
                        sum_total_vat
                        status_payment
                        delivery_order_number
                        outstanding_amount
                        total_conv_amount
                        conv_amount
                    }
                }
            }
        `;    
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetPurchasingInvoiceReport
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    invoice_date: kendo.toString(kendo.parseDate(data[i].invoice_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    invoice_number: data[i].invoice_number,
                    supplier_invoice_number: data[i].supplier_invoice_number,
                    delivery_order_number: data[i].delivery_order_number,
                    tax_invoice: data[i].tax_invoice,
                    product_code: data[i].product_code,
                    item_name: data[i].item_name,
                    quantity: data[i].quantity,
                    total_conv_amount: data[i].total_conv_amount,
                    price_per_unit: kendo.toString(data[i].price_per_unit, "n2"),
                    price: kendo.toString(data[i].price, "n2"),
                    ppn_out: kendo.toString(data[i].ppn_out, "n2"),
                    vat: kendo.toString(data[i].vat, "n2"),
                    supplier_name: data[i].supplier_name,  
                    notes: data[i].notes,
                    due_date: kendo.toString(kendo.parseDate(data[i].due_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    purchase_order_number: data[i].purchase_order_number,
                    outstanding_amount: data[i].outstanding_amount,
                    status_payment: data[i].status_payment,
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    purchasingInvoiceReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 30}, //colB
            {width: 30}, //colC
            {width: 30}, //colD
            {width: 40}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 50}, //colM
            {width: 30}, //colN
            {width: 20}, //colO
            {width: 30}, //colP
            {width: 30}, //colQ
            {width: 20}, //colR
        ];
        return size;
    }

    purchasingInvoiceReportExcel(data, variables){
        const uniques = [];
        data.forEach((t) => !uniques.includes(t.invoice_id) && uniques.push(t.invoice_id));

        var arrayObject = [];
        var spaceRow = {colA : null};
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'BUKU PEMBELIAN PIPA & FITTING',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'TANGGAL',
            colB : 'NO. FAKTUR',
            colC : 'NO. SJ',
            colD : 'NO. FAKTUR PAJAK',
            colE : 'NAMA BARANG',
            colF : 'QUANTITY',
            colG : 'QUANTITY SATUAN',
            colH : 'HARGA SATUAN',
            colI : 'HARGA',
            colJ : 'DPP',
            colK : 'PPN OUT',
            colL : 'PIUTANG',
            colM : 'NAMA VENDOR',
            colN : 'KETERANGAN',
            colO : 'JATUH TEMPO',
            colP : 'NO. PO',
            colQ : 'OUTSTANDING',
            colR : 'STATUS PAYMENT',
            
        };
        arrayObject.push(row6);

        if(data != null){
            var totalDPP = 0;
            var totalPPNOut = 0;
            var totalPiutang = 0;

            for (let k= 0; k < uniques.length; k++) {                
                var detail = data.filter(c => c.invoice_id == uniques[k])
                var totalQty = 0;
                var totalConvQty = 0;
                var totalPricePerUnit = 0;
                var totalPrice = 0;

                for (let i = 0; i < detail.length; i++){
                    var row = {
                        colA : (detail[i].invoice_date == null || detail[i].invoice_date == '') ? '-' : { v: detail[i].invoice_date, t: 'd', z: 'DD-MMM-YYYY' },
                        colB : detail[i].supplier_invoice_number,
                        colC : detail[i].delivery_order_number,
                        colD : detail[i].tax_invoice,
                        colE : detail[i].item_name,
                        colF : detail[i].quantity,
                        colG : detail[i].total_conv_amount,
                        colH : { v: detail[i].price_per_unit, t: 'n' , z: numberFormat },
                        colI : { v: detail[i].price, t: 'n' , z: numberFormat },
                        colJ : { v: detail[i].price, t: 'n' , z: numberFormat },
                        colK : { v: detail[i].ppn_out, t: 'n' , z: numberFormat },
                        colL : { v: detail[i].vat, t: 'n' , z: numberFormat },
                        colM : detail[i].supplier_name,
                        colN : detail[i].notes,
                        colO : (detail[i].due_date == null || detail[i].due_date == '') ? '-' : { v: detail[i].due_date, t: 'd', z: 'DD-MMM-YYYY' },
                        colP : detail[i].purchase_order_number,
                        colQ : { v: detail[i].outstanding_amount, t: 'n' , z: numberFormat },
                        colR : detail[i].status_payment,
                    };                
                    arrayObject.push(row);

                    totalQty += detail[i].quantity;
                    totalConvQty += detail[i].total_conv_amount;
                    totalPrice += detail[i].price;
                    totalPricePerUnit += detail[i].price_per_unit;
                }
    
                var sumData = data.find(c => c.invoice_id == uniques[k]);
                
                var rowTotal = {
                    colA : (sumData.invoice_date == null || sumData.invoice_date == '') ? '-' : { v: sumData.invoice_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colB : sumData.invoice_number,
                    colC : sumData.delivery_order_number,
                    colD : sumData.tax_invoice,
                    colE : '',
                    colF : totalQty,
                    colG : totalConvQty,
                    colH : '-',
                    colI : '-',
                    colJ : { v: sumData.sum_total_price, t: 'n' , z: numberFormat },
                    colK : { v: sumData.sum_total_ppn_out, t: 'n' , z: numberFormat },
                    colL : { v: sumData.sum_total_vat, t: 'n' , z: numberFormat },
                    colM : sumData.supplier_name,
                    colN : sumData.notes,
                    colO : (sumData.due_date == null || sumData.due_date == '') ? '-' : { v: sumData.due_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colP : sumData.purchase_order_number,
                    colQ : { v: sumData.outstanding_amount, t: 'n' , z: numberFormat },
                    colR : sumData.status_payment,
                };
                
                //declare total
                totalDPP = totalDPP + sumData.sum_total_price;
                totalPPNOut = totalPPNOut + parseInt(sumData.sum_total_ppn_out);
                totalPiutang = totalPiutang + parseInt(sumData.sum_total_vat);
           
                arrayObject.push(rowTotal);
                
                arrayObject.push(spaceRow);
            }
            
            //set grand total
            arrayObject.push(spaceRow);
            var rowGrandTotal = {
                colA : null,
                colB : null,
                colC : null,
                colD : null,
                colE : null,
                colF : null,
                colG : 'TOTAL',
                colH : { v: totalPricePerUnit, t: 'n' , z: numberFormat },
                colI : { v: totalPrice, t: 'n' , z: numberFormat },
                colJ : { v: totalDPP, t: 'n' , z: numberFormat },
                colK : { v: totalPPNOut, t: 'n' , z: numberFormat },
                colL : { v: totalPiutang, t: 'n' , z: numberFormat },
                colM : null,
                colN : null,
                colO : null,
                colP : null,
                colQ : null,
                colR : null
            };
            arrayObject.push(rowGrandTotal);
        }

        return arrayObject;
    }
}

export default new PurchasingInvoiceReportService();