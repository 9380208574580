import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PurchasingDeliveryOrderReportService {    
    readPurchasingDeliveryOrderReport(){
        var query = `query($startDate:String!,$endDate:String!) {
            GetPurchasingDeliveryOrderReport(start_date:$startDate, end_date:$endDate){
                summary{
                    quantity_per_status{
                        new
                        posting
                        received
                        delete
                    }
                    quantity_per_delivery{
                        new
                        posting
                        received
                        delete
                    }
                }
                detail {
                    delivery_order_id
                    delivery_order_number
                    status
                    invoice_id
                    invoice_number
                    delivery_order_date
                    delivery_order_received_date
                    supplier_id
                    supplier_code
                    supplier_name
                    storage_id
                    storage_name
                    notes
                    printed_notes
                    address
                    attachment
                    created_by
                    purchase_order_id
                    purchase_order_number
                    created_at
                    last_update
                    item_id
                    product_code
                    item_name
                    uom
                    quantity
                    order_quantity
                    delivered_quantity
                    remaining_quantity
                }
            }
        }`;
        return query;
    }

    async getPurchasingDeliveryOrderReport(variables) {
        var query = gql`query($startDate:String!,$endDate:String!) {
            GetPurchasingDeliveryOrderReport(start_date:$startDate,end_date:$endDate){
                summary{
                    quantity_per_status{
                        new
                        posting
                        received
                        delete
                    }
                    quantity_per_delivery{
                        new
                        posting
                        received
                        delete
                    }
                }
                detail{
                    delivery_order_id
                    delivery_order_number
                    status
                    invoice_id
                    invoice_number
                    delivery_order_date
                    delivery_order_received_date
                    supplier_id
                    supplier_code
                    supplier_name
                    storage_id
                    storage_name
                    notes
                    printed_notes
                    address
                    attachment
                    created_by
                    purchase_order_id
                    purchase_order_number
                    created_at
                    last_update
                    item_id
                    product_code
                    item_name
                    uom
                    quantity
                    order_quantity
                    delivered_quantity
                    remaining_quantity
                }
            }
        }`    
        var result = await globalfunc.defaultApolloMutationDefault(query, variables);
        return result.data.GetPurchasingDeliveryOrderReport
    }

    purchasingDeliveryOrderReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN

        ];
        return size;
    }

    purchasingDeliveryOrderReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'PURCHASING INVOICE REPORT',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'STATUS',
            colB : 'NO. PO',
            colC : 'NO. DELIVERY',
            colD : 'TGL. DELIVERY',
            colE : 'TGL. TERIMA',
            colF : 'TGL. BUAT',
            colG : 'VENDOR',
            colH : 'CATATAN',
            colI : 'KODE BARANG',
            colJ : 'NAMA BARANG',
            colK : 'SATUAN',
            colL : 'DELIVERY QUANTITY',
            colM : 'REMAINING QUANTITY',
            colN : 'QUANTITY',            
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].status,
                    colB : data[i].purchase_order_number,
                    colC : data[i].delivery_order_number,
                    colD : kendo.toString(kendo.parseDate(data[i].delivery_order_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    colE : kendo.toString(kendo.parseDate(data[i].delivery_order_received_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    colF : kendo.toString(kendo.parseDate(data[i].created_at.toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    colG : data[i].supplier_name,
                    colH : data[i].notes,
                    colI : data[i].product_code,
                    colJ : data[i].item_name,
                    colK : data[i].uom,
                    colL : data[i].delivered_quantity,
                    colM : data[i].remaining_quantity,
                    colN : data[i].quantity,
                };                
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}

export default new PurchasingDeliveryOrderReportService();