import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class ProductionReportService {
    readQuery(){
        var query = `
        query ($startDate:String!, $endDate:String!, $machineId:Int, $machineTypeId:Int, $itemId:String, $typeDetailId:String, $downtime:Boolean) {
            GetProductionReport(start_date:$startDate, end_date:$endDate, machine_id:$machineId, machine_type_id:$machineTypeId, 
                item_id:$itemId, type_detail_id:$typeDetailId, has_down_time:$downtime) {
                    total_weight_production_result
                    total_weight_waste_result
                    total_weight_loss_result
                    total_weight_used_material
                    total_quantity_production_planning_result
                    total_quantity_production_result
                    details {
                        machine_type_name
                        machine_type_code
                        production_id
                        production_code
                        product_code
                        item_id
                        item_name
                        type_detail_id
                        machine_code
                        machine_name
                        start_estimation
                        end_estimation
                        start_date
                        end_date
                        total_weight_planning_material
                        total_weight_used_material
                        total_weight_production_result
                        total_weight_waste_result
                        total_weight_loss_result
                        total_quantity_production_planning_result
                        total_quantity_production_result
                        total_quantity_waste_result
                        total_waste_percentage
                        downtime
                        production_report_detail_material {
                            item_name
                            product_code
                            base_uom
                            type_detail_id
                            type_detail_name
                            quantity_used_material
                            weight_used_material
                        }
                        production_report_detail_result {
                            item_id
                            item_name
                            product_code
                            item_type
                            base_uom
                            quantity_result
                            weight_result
                            secondary_conv_amount
                            secondary_uom
                            secondary_quantity_result
                            production_mutation_id
                            production_mutation_code
                            production_mutation_date
                            waste_percentage
                            base_weight
                        }
                    }
                }
            }
        `;
        return query;
    }

    async getProductionReportCountQuery(variables){
        var query = gql`
            query ($startDate: String!, $endDate: String!, $machineId:Int, $machineTypeId:Int, $itemId:String, $typeDetailId:String. $downtime:Boolean) {
                GetProductionReport(start_date:$startDate, end_date:$endDate, machine_id:$machineId, machine_type_id:$machineTypeId, 
                    item_id:$itemId, type_detail_id:$typeDetailId, has_down_time:$downtime)
                {
                    total_weight_production_result
                    total_weight_waste_result
                    total_weight_loss_result
                    total_weight_used_material
                    total_quantity_production_planning_result
                    total_quantity_production_result
                }
            }
        `;    
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetProductionReport;
    }
    
    async getMachineQuery(){
        var query = gql`
            query {
                GetMachine {
                    machine {
                        machine_id
                        machine_code
                        machine_name
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetMachine != null) {
            for (let i = 0; i < result.data.GetMachine.machine.length; i++) {
                var str = { 
                    value: result.data.GetMachine.machine[i].machine_id, 
                    label: result.data.GetMachine.machine[i].machine_code + ' (' + result.data.GetMachine.machine[i].machine_name + ')'
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getMachineTypeQuery(){
        var query = gql`
            query {
                GetMachineType {
                    machine_type {
                        machine_type_id
                        machine_type_name
                        machine_type_code
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetMachineType != null) {
            for (let i = 0; i < result.data.GetMachineType.machine_type.length; i++) {
                var str = { 
                    value: result.data.GetMachineType.machine_type[i].machine_type_id, 
                    label: result.data.GetMachineType.machine_type[i].machine_type_code + ' (' + result.data.GetMachineType.machine_type[i].machine_type_name + ')'
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }
    
    async getTypeDetailQuery(){
        var query = gql`
            query {
                GetTypeDetail {
                    type_detail_id
                    type_detail_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];

        if (result.data.GetTypeDetail != null) {
            for (let i = 0; i < result.data.GetTypeDetail.length; i++) {
                var str = { 
                    value: result.data.GetTypeDetail[i].type_detail_id, 
                    label: result.data.GetTypeDetail[i].type_detail_name
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }
    
    async getItemQuery(typeDetailId){
        const variables = {
            typeDetail : typeDetailId
        }

        var query = gql`
            query ($typeDetail:String) {
                GetProductMaster (typeDetailID:$typeDetail) {
                    ProductMaster {
                        item_id
                        product_code
                        item_name
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        var arrayData = [];

        if (result.data.GetProductMaster != null) {
            for (let i = 0; i < result.data.GetProductMaster.ProductMaster.length; i++) {
                var str = { 
                    value: result.data.GetProductMaster.ProductMaster[i].item_id, 
                    label: result.data.GetProductMaster.ProductMaster[i].product_code + ' (' + result.data.GetProductMaster.ProductMaster[i].item_name + ')'
                }
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    productionReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN
            {width: 20}, //colO
            {width: 20}, //colP
            {width: 20}, //colQ
        ];

        return size;
    }
    productionReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'REPORT PRODUKSI',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row5 = {
            colA : 'TIPE DETAIL',
            colB : 'KODE ITEM',
            colC : 'NAMA ITEM',
            colD : 'KODE MESIN',
            colE : 'NAMA MESIN',
            colF : 'ESTIMASI MULAI',
            colG : 'TANGGAL MULAI',
            colH : 'ESTIMASI SELESAI',
            colI : 'TANGGAL SELESAI',
            colJ : 'TOTAL BERAT BAHAN BAKU PERENCANAAN',
            colK : 'TOTAL BERAT BARANG JADI',
            colL : 'TOTAL BERAT WASTE',
            colM : 'TOTAL BERAT LOSS',
            colN : 'TOTAL PERENCAAN PRODUKSI',
            colO : 'TOTAL BARANG JADI',
            colP : 'WAKTU DOWNTIME',
            colQ : 'CATATAN',
        };
        arrayObject.push(row5);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].type_detail_id,
                    colB : data[i].product_code,
                    colC : data[i].item_name,
                    colD : data[i].machine_code,
                    colE : data[i].macine_name,
                    colF : data[i].start_estimation,
                    colG : data[i].start_date,
                    colH : data[i].end_estimation,
                    colI : data[i].end_date,
                    colJ : { v: data[i].total_weight_planning_material, t: 'n' , z: numberFormat },
                    colK : { v: data[i].total_weight_production_result, t: 'n' , z: numberFormat },
                    colL : { v: data[i].total_weight_waste_result, t: 'n' , z: numberFormat },
                    colM : { v: data[i].total_weight_loss_result, t: 'n' , z: numberFormat },
                    colN : { v: data[i].total_quantity_production_planning_result, t: 'n' , z: numberFormat },
                    colO : { v: data[i].total_quantity_production_result, t: 'n' , z: numberFormat },
                    colP : data[i].downtime,
                    colQ : data[i].notes,
                };
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new ProductionReportService();